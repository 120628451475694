import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvironmentInterface } from './environments/environment.interface';
import { EnvironmentService } from './environments/environment.service';

function initEnvironment(environment: EnvironmentInterface) {
  const injector = Injector.create({
    providers: [{ provide: EnvironmentService, useClass: EnvironmentService, deps: [] }],
  });
  const environmentService = injector.get(EnvironmentService);
  environmentService.set(environment);

  if (environment.production) {
    enableProdMode();
  }
}

// the config file 'environments/environment.json' is only used for local development tests
// the config file will be replaced into the deployed S3 bucket by a config file with
// the targeted settings per deployed environment
fetch('./assets/environments/environment.json')
  .then(resp => resp.json())
  .then(environment => {
    initEnvironment(environment as EnvironmentInterface);

    // Dynamic import here, so that EnvironmentService be correctly initialized and injected
    // workaround found here: https://stackblitz.com/edit/angular6-intercom-issue-shhf69?file=src%2Fmain.ts
    import('./app/app.module')
      .then(module => {
        platformBrowserDynamic()
          .bootstrapModule(module.AppModule)
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  });
