import { Injectable } from '@angular/core';
import { EnvironmentInterface } from './environment.interface';

export let ENVIRONMENT_CONFIG: EnvironmentInterface = {} as any;

@Injectable()
export class EnvironmentService {
  public set(environment: EnvironmentInterface) {
    ENVIRONMENT_CONFIG = Object.assign(environment);
  }
}
